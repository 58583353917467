import { render, staticRenderFns } from "./GooglePay.vue?vue&type=template&id=fdc8e342&scoped=true&"
import script from "./GooglePay.vue?vue&type=script&lang=js&"
export * from "./GooglePay.vue?vue&type=script&lang=js&"
import style0 from "./GooglePay.vue?vue&type=style&index=0&id=fdc8e342&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc8e342",
  null
  
)

export default component.exports