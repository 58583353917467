<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-18 20:37:41
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-05 23:26:05
 * @Description  : 谷歌支付tappay
-->
<template>
	<div class="container">
		<div class="ui grid centered stackable">
			<div class="row"></div>
			<div class="row"></div>
			<br>
			<br>
			<div id="container"></div>
			<div class="fourteen wide column">
				<div class="ui info message hidden result1">
					<div class="ui header">Get Prime Result</div>
					<pre class="content" id="result1" style="overflow-x: auto;"></pre>
				</div>
				<div class="ui success message hidden curl">
					<div class="ui header">Pay Result</div>
					<pre class="content" id="curl" style="overflow-x: auto;"></pre>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {};
	},
	methods: {
		initGooglePay() {
			var googlePaySetting = {
				googleMerchantId: "Come from google portal",
				tappayGoogleMerchantId: "0100063143", //Come from tappay portal
				allowedCardAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
				merchantName: "TapPay Test!",
				emailRequired: true, // optional
				shippingAddressRequired: true, // optional,
				billingAddressRequired: true, // optional
				billingAddressFormat: "MIN", // FULL, MIN

				allowPrepaidCards: true,
				allowedCountryCodes: ["TW"],

				phoneNumberRequired: true, // optional
			};
			window.TPDirect.googlePay.setupGooglePay(googlePaySetting);
			var paymentRequest = {
				allowedNetworks: ["AMEX", "JCB", "MASTERCARD", "VISA"],
				price: "123", // optional
				currency: "TWD", // optional
			};
			window.TPDirect.googlePay.setupPaymentRequest(
				paymentRequest,
				function (err, result) {
					console.log("result: ", result);
					if (result.canUseGooglePay) {
						window.TPDirect.googlePay.setupGooglePayButton({
							el: "#container",
							color: "black",
							type: "long",
							getPrimeCallback: function (err, prime) {
								console.log(
									"paymentRequestApi.getPrime result",
									prime
								);
								this.handlePayByPrime(prime);
							},
						});
					}
				}
			);
		},
		handlePayByPrime(result) {
			document.querySelector("#result1").innerHTML = JSON.stringify(
				result,
				null,
				4
			);
			document.querySelector(".result1").classList.remove("hidden");
			document.querySelector(".curl").classList.remove("hidden");
			var command = `
        curl -X POST https://sandbox.tappaysdk.com/tpc/payment/pay-by-prime \\
        -H 'content-type: application/json' \\
        -H 'x-api-key: partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM' \\
        -d '{
            "partner_key": "partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM",
            "prime": "${result}",
            "amount": "1",
            "merchant_id": "GlobalTesting_CTBC",
            "details": "Some item",
            "cardholder": {
                "phone_number": "0987654321",
                "name": "王小明",
                "email": "test@example.com",
                "zip_code": "123",
                "address": "台北市xxx街xx號",
                "national_id": "A123456789"
            }
        }'`.replace(/^/gm, "");

			document.querySelector("#curl").innerHTML = command;
		},
	},
	props: {},
	components: {},
	computed: {},

	mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
		this.initGooglePay();
    },
	created() {},
};
</script>

<style scoped lang="less">
#container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>
